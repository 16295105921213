<template>
    <admin-dashboard-layout>
        <v-card>
            <v-card-title>
                <v-row>
                    <v-col>
                    </v-col>
                    <v-col class="text-right">
                        <v-btn text :to="parent_page">return</v-btn>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-card-text>
                <v-form>
                    <v-row>
                        <v-col>
                            <v-text-field label="Code" :error-messages="errors.code" v-model="country.code"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field label="Name" :error-messages="errors.name" v-model="country.name"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-checkbox label="Active" v-model="country.is_active"></v-checkbox>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field label="Created" :value="createdAt" disabled></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field label="Updated" :value="updatedAt" disabled></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-btn class="warning" @click="handleUpdate">Update</v-btn>
                        </v-col>
                        <v-col>
                            <v-btn class="error" @click="handleDelete">Delete</v-btn>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
        </v-card>
    </admin-dashboard-layout>
</template>

<script>
    import AdminDashboardLayout from "@/layouts/AdminDashboardLayout";
    import Country from "@/models/admin/Country";
    import moment from "moment";
    import {mapMutations} from "vuex";

    export default {
        name: "country-show",
        components: {
            AdminDashboardLayout,
        },
        data: function () {
            return {
                parent_page: {name: 'admin.countries.index'},
                country: {},
                errors: {},
            }
        },
        computed: {
            createdAt() {
                return this.country.created_at ? moment(this.country.created_at).format('LLL') : ''
            },
            updatedAt() {
                return this.country.updated_at ? moment(this.country.updated_at).format('LLL') : ''
            },
        },
        methods: {
            ...mapMutations(['showSnackBar']),
            async handleUpdate() {
                this.country.save().then(async () => {
                    this.errors = {}
                    this.country = await Country.find(this.$route.params.id)
                    this.showSnackBar(this.$t('messages.success'))
                }).catch((e) => {
                    this.errors = e.response.data.errors
                })
            },
            handleDelete() {
                this.country.delete()
                this.$router.push(this.parent_page)
            },
        },
        async mounted() {
            this.country = await Country.find(this.$route.params.id)
        },
    }
</script>

<style lang="scss">
</style>
